import classNames from 'classnames';
export interface SectionHeaderProps {
  className?: string;
  link?: boolean;
  setDefaultColor?: boolean;
  variant:
    | 'plakat-smallest'
    | 'plakat-smaller'
    | 'plakat-small'
    | 'plakat-large'
    | 'plakat-xl'
    | 'respira'
    | 'respira-small'
    | 'respira-large';
  transparentBackground?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  pageheader?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  className,
  link,
  setDefaultColor = true,
  variant,
  transparentBackground = false,
  pageheader = false,
}) => {
  const defaultColor = setDefaultColor
    ? 'text-blank-yellow'
    : 'text-blank-black';

  const classes = classNames({
    'font-plakat text-plakat-smallest leading-blank-tighter tracking-blank-tighter':
      variant === 'plakat-smallest',
    'font-plakat text-plakat-smaller leading-blank-tighter tracking-blank-tighter uppercase':
      variant === 'plakat-smaller',
    'font-plakat text-plakat-small leading-blank-tighter tracking-blank-tighter uppercase':
      variant === 'plakat-small',
    'font-plakat  laptop:text-plakat-large tablet:text-plakat-small text-plakat-smaller leading-blank-tighter tracking-blank-tighter uppercase':
      variant === 'plakat-large',
    'font-plakat  text-plakat-xl leading-blank-tighter tracking-blank-tighter uppercase':
      variant === 'plakat-xl',
    'font-respira text-respira-small laptop:text-respira-large leading-blank-snug tracking-blank-tight':
      variant === 'respira',
    'font-respira text-respira-small leading-blank-snug tracking-blank-tight':
      variant === 'respira-small',
    'font-respira laptop:text-respira-large tablet:text-respira-large text-respira-mobile leading-blank-tight tracking-blank-tight':
      variant === 'respira-large',
  });

  return pageheader ? (
    <h1
      className={`relative break-words w-fit ${
        link && 'children:hover:bg-transparent'
      }`}
    >
      <div
        className={`absolute opacity-90 transition-50ms ${className} ${classes}  ${defaultColor}`}
      >
        {children}
      </div>
      <div className={`${className} no-underline`}>
        <span
          className={`${
            transparentBackground ? 'bg-transparent' : 'bg-blank-black'
          } transition-bg-1s ${className} ${classes} text-transparent`}
        >
          {children}
        </span>
      </div>
    </h1>
  ) : (
    <h2
      className={`relative break-words w-fit ${
        link && 'children:hover:bg-transparent'
      }`}
    >
      <div
        className={`absolute opacity-90 transition-50ms ${className} ${classes}  ${defaultColor}`}
      >
        {children}
      </div>
      <div className={`${className} no-underline`}>
        <span
          className={`${
            transparentBackground ? 'bg-transparent' : 'bg-blank-black'
          } transition-bg-1s ${className} ${classes} text-transparent`}
        >
          {children}
        </span>
      </div>
    </h2>
  );
};
export default SectionHeader;
